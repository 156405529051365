import request from '@/utils/request'
import apiConfig from '@/api/apiconfig'
/**
 * 权益入口
 */
// export function right(data) {
//   return request({
//     url: `${process.env.VUE_APP_CARDBAG_GXZ3_API}${apiConfig.ZY}/right`,
//     method: 'post',
//     data,
//     token: data.token
//   })
// }
/**
 * 统计用户领取记录
 */
export function countUserReceive(data) {
  return request({
    url: `${apiConfig.ZY}/unionAct/countUserReceive`,
    method: 'post',
    token: sessionStorage.getItem('unionActToken'),
    data,
  })
}
/**
 * 领取权益
 */
export function receiveCard(data) {
  return request({
    url: `${apiConfig.ZY}/unionAct/receiveCard`,
    method: 'post',
    token: sessionStorage.getItem('unionActToken'),
    data,
    custom: true

  })
}
/**
 * 分页查询用户订单
 */
export function pageUserOrderCard(data) {
  return request({
    url: `${apiConfig.ZY}/unionAct/pageUserOrderCard`,
    method: 'post',
    token: sessionStorage.getItem('unionActToken'),
    data,
  })
}