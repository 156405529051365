<template>
  <!-- 银联-领取记录 -->
  <div class="record">
    <van-tabs
      v-model:active="active"
      background="#000"
      color="#C89D7A"
      title-inactive-color="#FFECD6"
      title-active-color="#C89D7A"
      @change="onChange"
    >
      <van-tab
        v-for="item in tabArr"
        :key="item.name"
        :name="item.value"
        :title="item.name"
        >{{ item.name }}</van-tab
      >
    </van-tabs>

    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <ul class="list">
        <li v-for="item in list">
          <h4>领取信息</h4>
          <p>
            <span class="label">领取编号:</span>
            <span class="value">{{ item.orderNo }}</span>
          </p>
          <p>
            <span class="label">领取时间:</span>
            <span class="value">{{ item.orderTime }}</span>
          </p>
          <p>
            <span class="label">领取结果:</span>
            <span class="value">{{ orderStatusMap[item.orderStatus] }}</span>
          </p>
          <div class="van-hairline--top"></div>
          <h4>商品信息</h4>
          <p>
            <span class="label">商品名称:</span>
            <span class="value">{{ item.goodsName || '等待发放' }}</span>
          </p>
          <p>
            <span class="label">券码:</span>
            <span class="value">{{
              codeTypeMap[item.codeType] || '等待发放'
            }}</span>
          </p>
          <p v-if="item.cardNo">
            <span class="label">卡号:</span>
            <span
              class="value"
              v-clipboard:copy="item.cardNo"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >{{ item.cardNo }}</span
            >
          </p>
          <p v-if="item.cardPwd">
            <span class="label">卡密:</span>
            <span
              class="value"
              v-clipboard:copy="item.cardPwd"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >{{ item.cardPwd }}</span
            >
          </p>
          <p v-if="item.shortUrl">
            <span class="label">短链:</span>
            <span
              class="value"
              v-clipboard:copy="item.shortUrl"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >{{ item.shortUrl }}</span
            >
          </p>
          <p v-if="item.verifyCode">
            <span class="label">验证码:</span>
            <span
              class="value"
              v-clipboard:copy="item.verifyCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >{{ item.verifyCode }}</span
            >
          </p>
          <p v-if="item.redeemCode">
            <span class="label">兑换码:</span>
            <span
              class="value"
              v-clipboard:copy="item.redeemCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >{{ item.redeemCode }}</span
            >
          </p>
          <p>
            <span class="label">有效期:</span>
            <span class="value">{{ item.expireTime || '等待发放' }}</span>
          </p>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import { pageUserOrderCard, } from '@/api/unionAct'
export default {
  data() {
    return {
      active: -1,
      tabArr: [
        {
          name: '全部',
          value: -1,
        },
        {
          name: '发放中',
          value: 0,
        },
        {
          name: '已完成',
          value: 1,
        },
      ],
      loading: false,
      finished: false,
      list: [
        // {
        //   orderNo: 'orderNo',
        //   orderTime: 'orderTime',
        //   orderStatus: 1,
        //   goodsName: 'goodsName',
        //   codeId: 'codeId',
        //   cardNo: 'cardNo',
        //   cardPwd: 'cardPwd',
        //   shortUrl: 'http://192.168.58.22:8080/#/unionAct/index?token=539b055ee42831b107a32dee02ee1f53',
        //   verifyCode: '539b055ee42831b107a32dee02ee1f53',
        // },

      ],
      msgType: 0,
      pageNum: 1,
      pageSize: 10,
      total: 30,
      orderStatusMap: {
        0: '发放中',
        1: '发放成功',
        2: '发放失败'
      },
      codeTypeMap: {
        0: '卡号卡密',
        1: '卡密',
        2: '卡号短链',
        3: '卡号短链验证码',
        4: '兑换码',
      },

    }
  },
  methods: {
    onLoad() {
      // setTimeout(() => {
      //   console.log(this.pageNum);
      //   this.list.push(...this.list);
      //   this.loading = false;
      //   if ((this.pageNum * this.pageSize) >= this.total) {
      //     this.finished = true;
      //   }
      //   this.pageNum++;
      // }, 500);

      pageUserOrderCard({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        param: {
          orderStatus: this.active < 0 ? undefined : this.active
        }
      }).then(res => {
        let { records, total } = res.data
        this.total = total
        console.log(this.pageNum);
        if (this.pageNum === 1) {
          this.list = records;
        } else {
          this.list.push(...records);
        }
        this.loading = false;
        if ((this.pageNum * this.pageSize) >= this.total) {
          this.finished = true;
        }
        this.pageNum++;
      }).catch(() => {
        this.loading = false;
        this.finished = true;
      })
    },
    onChange() {
      // console.log(val);
      this.pageNum = 1;
      this.onLoad()
    },
    onCopy() {
      this.$toast.success('复制成功！')
    },
    onError() { }


  },
}
</script>

<style lang="less" scoped>
.record {
  width: 100%;
  min-height: 100vh;
  background-size: 100%, 100%;
  padding: 0 0 2px;
  box-sizing: border-box;
  background-color: #010303;
  position: relative;
  overflow: hidden;
  /deep/ .van-tabs {
    margin: 20px 0 10px 0;
  }
  /deep/ .van-tab--active {
    font-weight: bold;
  }

  .list {
    margin: 0 20px;
    li {
      margin-bottom: 20px;
      padding: 20px;
      background: #17181e;
      border-radius: 10px;
      border: 1px solid #2a2d3c;
      font-size: 14px;
      color: #ffecd6;
      line-height: 1.5;
    }
    h4 {
      font-size: 18px;
      color: #c89d7a;
    }
    p {
      display: flex;
      align-items: flex-start;
      padding: 10px 0;
    }
    .label {
      display: inline-block;
      width: 80px;
    }
    .value {
      display: inline-block;
      width: 75%;
      word-wrap: break-word;
    }

    .van-hairline--top {
      margin: 20px 0 40px 0;
      &::after {
        border-color: #2c2e39;
      }
    }
  }
}
</style>
